<template>
  <div>
    <template v-if="isEntryRoute">
      <entity-entry-header :entityKey="eCurrent.key" :title="$t(`views.${eCurrent.key}.title`)"/>
    </template>

    <!-- if this is active route (e.g. /projects) -->
    <template v-if="isEntryRoute">
      <b-row class="mb-3" align-h="between">
        <b-col cols="auto"></b-col>
        <b-col cols="auto">
          <b-button variant="outline-primary" @click="navigate('Projects.Add')"><b-icon icon="plus"/> {{ $t('views.projects.button.addproject') }}</b-button>
        </b-col>
      </b-row>

      <entity-entry-table :entityKey="eCurrent.key" :tableSettings="table" searchable>
        <template #cell(requiredactions)="data">
          <div class="badge-group">
            <b-badge v-for="action in data.cell.item.requiredactions" :key="action" variant="warning">{{ action }}</b-badge>
          </div>
        </template>
      </entity-entry-table>
    </template>

        <!-- if subroute is active route (e.g. /projects/:projectid) -->
    <section v-else-if="isDetailRoute">
      <entity-detail-header :entityKey="eCurrent.key" :entityId="eCurrent.id">
        <b-row align-v="end">
          <b-col lg>
            <h1 v-if="this.entity.value.isArchived"><b-alert variant="danger"  show>Projekt ist Archiviert!</b-alert></h1>
            <p class="projectname">{{this.entity.value.name}}</p>
            <dl class="detailheader-list">
              <template >
                <dt>Kunde:</dt>
                 <dd>{{this.extractClientName(this.entity)}}</dd>
              </template>
            </dl>
            </b-col>
        </b-row>
      </entity-detail-header>
      <router-view/>
    </section>

<router-view v-else-if="isDetailRouteWithoutParameter"/>

  </div>
</template>

<script>
import { CLASS_TABLE_FIELD_KEY_UNKNOWN } from '@/constants'

import { TableHelper } from '@/assets/js/helper/table'

import MixinEntityBase from '@/components/mixins/EntityBase'
import EntityEntryHeader from '@/components/entity/EntryHeader'
import EntityEntryTable from '@/components/entity/EntryTable'
import EntityDetailHeader from '@/components/entity/DetailHeader'

const ENTITY_KEY = 'projects'

export default {
  name: 'Projects',
  mixins: [MixinEntityBase],
  components: {
    EntityEntryHeader,
    EntityEntryTable,
    EntityDetailHeader
  },
  data () {
    return {
      entityKey: ENTITY_KEY,
      table: {
        helper: TableHelper,
        mapper: this.tableEntitiesMapper,
        options: {
          fields: {
            sorting: ['projectNumber', 'name', 'clientName', 'clickUpSpaceName', 'bexioProjectName', CLASS_TABLE_FIELD_KEY_UNKNOWN],
            labelKey: `views.${this.$options.name.toLowerCase()}.table.label.{field}`,
            class: {
              requiredactions: 'cell-align-right'
            },
            thClass: {
              requiredactions: 'cell-min cell-nowrap'
            }
          }
        },
        events: {
          rowClicked: this.tableRowClicked
        }
      }
    }
  },
  computed: {
    isEntryRoute () {
      return Object.values(this.$route.matched[this.$route.matched.length - 1].components).find(c => c.name === this.$options.name) !== undefined
    },
    isDetailRouteWithParameter () {
      return this.$route.matched[this.$route.matched.length - 1].path.indexOf(`/:${this.eCurrent.id}`) >= 0
    },
    isDetailRouteWithoutParameter () {
      return !this.isEntryRoute && !this.isDetailRouteWithParameter
    },
    isDetailRoute () {
      // check if the route looks as follows: /projects/{this.eCurrent.id}/{something}
      var isDetailRoute = this.$route.path.indexOf(`${this.eCurrent.id}`) >= 0
      return isDetailRoute
    },
    entity () {
      return this.$store.getters[`${this.entityKey}/getEntity`](this.eCurrent.id) || {}
    },
    definition () {
      return this.$store.getters[`${this.entityKey}/definition`]
    }
  },
  methods: {
    tableEntitiesMapper (entities, definition) {
      return entities
        .map(e => Object.assign({}, e, {
          $routerLink: {
            name: `${this.$options.name}.Detail`,
            params: {
              [this.eCurrent.paramKey]: e.id
            }
          }
        }))
    },
    tableRowClicked (entity) {
      this.$router.push(entity.$routerLink)
    },
    extractClientName (entity) {
      const clientId = entity.value.clientId
      const clientProperty = this.definition.properties.find(p => p.name === 'clientId')

      if (clientProperty && clientProperty.translations && clientProperty.translations['de-CH'] && clientProperty.translations['de-CH'].selectValues) {
        return clientProperty.translations['de-CH'].selectValues[clientId] || 'Unbekannter Kunde'
      }

      return 'Unbekannter Kunde'
    }
  },
  created () {
    TableHelper.rowClassHook(entity => entity.$routerLink ? 'has-routerlink' : null)
  }
}
</script>

<style lang="scss">
$entity-header-bottom-gap: $spacer * 1.4 !default;

$entity-header-separator-y-gap: $spacer * 0.8 !default;
$entity-header-separator-height: 5px !default;
$entity-header-separator-bg: $dark !default;

.entity-detailheader {
  margin-bottom: $entity-header-bottom-gap;

  .projectname {
    font-size: 1.8rem;
    font-weight: bold;
  }

  .detailheader-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0;

    > dt {
      margin-right: $spacer;
    }

    > dd {
      margin: 0;

      h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
        margin: 0;
      }
    }
  }

  .detailheader-separator {
    margin-top: $entity-header-separator-y-gap;
    height: $entity-header-separator-height;
    background-color: $entity-header-separator-bg;
  }
}
</style>
